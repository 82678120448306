/**
 * This function is copied to
 * `backend/src/api/service/util/getFirstAvailableOrdinationIdFromServices.ts`
 * If you edit this, please also adapt the other file to reflect the changes
 */
export const isPrimaryService = (name: string): boolean => {
  const ordinationNames = ["allgemeiner termin", "erster termin", "operativer eingriff"];

  const normalizedName = getAppointmentNameFromService(name).trim().toLowerCase();

  return ordinationNames.some((ordinationName) => normalizedName.startsWith(ordinationName));
};
